import { Component, Input, OnInit } from "@angular/core";
import { CommonModule, DatePipe, NgFor, NgIf } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { LocalizationModule } from "src/app/components/localization";
import { firstValueFrom } from "rxjs";
import { RouterModule } from "@angular/router";
import { TranslocoService } from "@ngneat/transloco";
import { ApiService } from "src/app/shared/constants/api.service";
import { Event } from "src/app/shared/models/event.model";
import { EventsSelectorService } from "src/app/shared/services/events-selector.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-waiver-and-info",
  templateUrl: "./waiver-and-info.component.html",
  standalone: true,
  imports: [
    NgFor,
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    LocalizationModule,
    CommonModule,
    RouterModule,
  ],
  styleUrls: ["./waiver-and-info.component.scss"],
  providers: [DatePipe],
})
export class WaiverAndInfoComponent implements OnInit {
  @Input() event: Event | undefined;
  currentLang: string = "en";

  currentInfo: string = "";
  currentWaiver: string = "";
  currentName: string = "";

  waiver: string = "";
  info: string = "";

  constructor(
    private apiService: ApiService,
    private eventsSelectorService: EventsSelectorService,
    private transloco: TranslocoService,
    private toastr: ToastrService
  ) {
    this.transloco.langChanges$.subscribe((lang) => {
      const currentLang = lang.split("-")[0];
      this.currentLang = currentLang;
    });
  }

  ngOnInit(): void {
    this.eventsSelectorService.selectedEvent$.subscribe(async (ev) => {
      this.event = { ...ev };
      if (this.event && this.event.id) {
        const data: any = await firstValueFrom(
          this.apiService.getWaiverAndInfo(this.event.id)
        );
        this.updateData(data);
      }
    });
  }

  updateData(data: any) {
    if (!data) {
      return;
    }
    if (data.waiver) {
      this.waiver = data.waiver;
      this.currentWaiver = data.waiver ?? "";
    }
    if (data.info) {
      this.info = data.info;
      this.currentInfo = data.info ?? "";
    }

    this.currentName = data.name ?? "";
  }

  async submitWaiver() {
    if (!this.event) {
      return;
    }
    try {
      const data = await firstValueFrom(
        this.apiService.putWaiverAndInfo(
          this.event.id,
          this.currentName,
          this.currentInfo,
          this.waiver
        )
      );
      this.updateData(data);
      this.toastr.success("Information updated");
    } catch (e) {
      console.error(e);
      this.toastr.error("There was an error, please try again");
    }
  }

  async submitInfo() {
    if (!this.event) {
      return;
    }
    try {
      const data = await firstValueFrom(
        this.apiService.putWaiverAndInfo(
          this.event.id,
          this.currentName,
          this.info,
          this.currentWaiver
        )
      );
      this.updateData(data);
      this.toastr.success("Information updated");
    } catch (e) {
      console.error(e);
      this.toastr.error("There was an error, please try again");
    }
  }
}
