<div class="main">
  <div
    class="side-panel"
    [ngClass]="{ collapsed: isCollapsed }"
    *ngIf="isSidePanelVisible"
  >
    <app-left-panel
      (close)="closeLeftPanel()"
      uiOutsideClick
      (outsideClick)="leftPanelClickOutside($event)"
      (collapse)="collapsePanel($event)"
    ></app-left-panel>
  </div>
  <div class="dashboard">
    <app-dashboard></app-dashboard>
  </div>
</div>
