<div class="component">
  <div class="header">
    <div class="header-title">{{ "WAIVER" | transloco }}</div>
  </div>

  <div class="card-text-area">
    <div class="title">
      {{ "WAIVER_TITLE" | transloco }}
    </div>
    <div class="subtitle">
      {{ "WAIVER_SUBTITLE" | transloco }}
    </div>
    <textarea
      class="text-area"
      placeholder="{{ 'START_TYPING...' | transloco }}"
      [(ngModel)]="waiver"
    >
    </textarea>
    <button class="submit-b" (click)="submitWaiver()">
      {{ "WAIVER_EDIT" | transloco }}
    </button>
  </div>

  <div class="card-text-area">
    <div class="title">
      {{ "WAIVER_INFO_TITLE" | transloco }}
    </div>
    <div class="subtitle">
      {{ "WAIVER_INFO_SUBTITLE" | transloco }}
    </div>
    <textarea
      class="text-area"
      placeholder="{{ 'START_TYPING...' | transloco }}"
      [(ngModel)]="info"
    >
    </textarea>
    <button class="submit-b" (click)="submitInfo()">
      {{ "WAIVER_INFO_EDIT" | transloco }}
    </button>
  </div>
</div>
